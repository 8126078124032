import { RAIDinLyonRiddleSearch, RiddleTable, RiddleTableType } from "@raidinlyon/core";
import { useNavigate } from "react-router-dom";


export default function RouteRiddleTable(props: { type: RiddleTableType }) {
    const navigate = useNavigate();

    const handleOpenRiddle = (riddle: RAIDinLyonRiddleSearch) => {
        navigate('/app/event/' + riddle.eventNumber + '/' + riddle.number)
    }

	return <RiddleTable onOpenRiddle={handleOpenRiddle} type={props.type} />
}