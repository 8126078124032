import './App.css';
import './i18n'
import { BrowserRouter } from 'react-router-dom';
import RoutesComponent from './Routes';
import React from 'react';

function App() {
  
  React.useEffect(() => {
    let title = "RAIDinLyon"

    if (process.env.REACT_APP_PROD_HOSTNAME !== document.location.hostname) {
      title = "Test: " + title;
    }

    document.title = title;
  }, [])

  return (
    <BrowserRouter>
      <RoutesComponent />
    </BrowserRouter>
  );
}

export default App;
