import { Route, Routes } from "react-router-dom"

import RouteMain from './component/RouteMain'
import RouteHome from "./component/RouteHome"
import RouteAppCore from "./component/RouteAppCore"
import RouteGatewayLogin from "./component/RouteGatewayLogin"
import RouteGatewayPassword from "./component/RouteGatewayPassword"
import RouteGatewayPasswordReset from "./component/RouteGatewayPasswordReset"
import RouteEvent from "./component/RouteEvent"
import RouteRiddle from "./component/RouteRiddle"
import RouteRiddleTable from "./component/RouteRiddleTable"
import RouteConfiguration from './component/RouteConfiguration'
import RouteConfigurationUsers from "./component/RouteConfigurationUsers"
import RouteConfigurationMenu from "./component/RouteConfigurationMenu"
import RouteConfigurationEvent from "./component/RouteConfigurationEvent"
import RouteConfigurationInfo from "./component/RouteConfigurationInfo"
import RouteConfigurationLog from "./component/RouteConfigurationLog"
import RouteConfigurationRiddle from "./component/RouteConfigurationRiddle"
import RouteConfigurationTrello from "./component/RouteConfigurationTrello"
import RoutePrinting from "./component/RoutePrinting"

export default function RoutesComponent() {

	return (
		<Routes>
			<Route path="/" element={<RouteMain />}>
				<Route path="/login" element={<RouteGatewayLogin />} />
				<Route path="/password" element={<RouteGatewayPassword />} />
				<Route path="/password/reset" element={<RouteGatewayPasswordReset />} />
				<Route path="/app" element={<RouteAppCore />}>
					<Route path='' element={<RouteHome />} />
					<Route path='event' element={<RouteEvent />} />
					<Route path='event/:eventnumber' element={<RouteEvent />} />
					<Route path='event/:eventnumber/printing' element={<RoutePrinting />} />
					<Route path='event/:eventnumber/:riddlenumber' element={<RouteRiddle />} />
					<Route path='riddle' element={<RouteRiddleTable type="all" />} />
					<Route path='riddle/editor' element={<RouteRiddleTable type="editor" />} />
					<Route path='riddle/proofreader' element={<RouteRiddleTable type="proofreader" />} />
					<Route path='config' element={<RouteConfiguration />}>
						<Route path='' element={<RouteConfigurationMenu />} />
						<Route path='event' element={<RouteConfigurationEvent />} />
						<Route path='trello' element={<RouteConfigurationTrello />} />
						<Route path='users' element={<RouteConfigurationUsers />} />
						<Route path='riddle' element={<RouteConfigurationRiddle />} />
						<Route path='log' element={<RouteConfigurationLog />} />
						<Route path='info' element={<RouteConfigurationInfo />} />
					</Route>
				</Route>
			</Route>
		</Routes>
	)
}