import { AppCore } from "@raidinlyon/core";
import { DrawerMenuItem } from "@raidinlyon/custom";
import { useUser } from "@raidinlyon/db";
import EventIcon from '@mui/icons-material/Event';
import { Outlet, useNavigate } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import { useTranslation } from "react-i18next";
import { useEvent } from "../Hook";

export default function RouteAppCore() {
	const navigate = useNavigate();
	const { t } = useTranslation('app');
	const event = useEvent();
	const user = useUser();

	const menu: DrawerMenuItem[] = [{
		Icon: HomeIcon,
		label: t('labelHome'),
		onClick: () => navigate('/app')
	}, {
		Icon: EventIcon,
		label: t('labelEvent'),
		onClick: () => navigate('/app/event')
	}]

	const menuAdmin: DrawerMenuItem[] = [{
		Icon: SettingsIcon,
		label: t('labelConfig'),
		onClick: () => navigate('/app/config')
	}]

	const getMenu = () => {
		const menus = [menu]

		if (user && user.admin) {
			menus.push(menuAdmin)
		}

		return menus;
	}

	return (
		<AppCore
			menu={getMenu()}
			title={event ? event.name : undefined}
			onTitleClick={event ? () => navigate('/app/event/' + event.number) : undefined}
		>
			<Outlet />
		</AppCore>
	)
}
