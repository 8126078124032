import { Provider, useCookieExists } from '@raidinlyon/core';
import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate, useMatch } from "react-router-dom";

const cookiename = 'rilid';

export default function RouteMain() {
    const navigate = useNavigate();
    const isapp = useMatch('/app/*');
    const islogin = useMatch('/login/*');
    const isPassword = useMatch('/password/*');
    const cookieexists = useCookieExists(cookiename);

    React.useEffect(() => {
      if (!islogin &&  ((!isapp && !isPassword) || (isapp && !cookieexists))) {
        navigate('/login', { replace: true })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    })
    
    const handleConnect = () => {
      if (!isapp && !isPassword) {
        navigate('/app', { replace: true });
      }
    }

    const handleDisconnect = () => {
      if (!islogin) {
        navigate('/login', { replace: true })
      }
    }

    return (
      <Provider 
        cookiename={cookiename}
        onConnect={handleConnect}
        onDisconnect={handleDisconnect}
        apibasepath={'/api'}
        wsoptions={{
          path: "/api/ws",
          transports: ['websocket']
        }}
      >
        {(!isapp || (isapp && cookieexists)) && <Outlet />}
      </Provider>
    )
}

