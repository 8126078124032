import { RAIDinLyonMainRiddleShort, Event, RAIDinLyonEvent } from "@raidinlyon/core";
import { useNavigate, useParams } from "react-router-dom";

export default function RouteEvent() {
	const { eventnumber } = useParams();
    const navigate = useNavigate();
    
    const handleOpenRiddle = (_event: RAIDinLyonEvent<RAIDinLyonMainRiddleShort>, riddle: RAIDinLyonMainRiddleShort) => {
        navigate('/app/event/' + eventnumber + '/' + riddle.number)
    }

    const handleOpenEvent = (event: RAIDinLyonEvent<RAIDinLyonMainRiddleShort>) => {
        navigate('/app/event/' + event.number)
    }

    const handleOpenPrinting = (event: RAIDinLyonEvent<RAIDinLyonMainRiddleShort>) => {
        navigate('/app/event/' + event.number + '/printing')
    }

    return <Event eventNumber={eventnumber ? Number(eventnumber) : undefined} onSelectEvent={handleOpenEvent} onSelectRiddle={handleOpenRiddle} onOpenPrinting={handleOpenPrinting} />
}