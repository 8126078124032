import { i18n } from '@raidinlyon/i18n'

i18n.addResourceBundle('en', 'app', {
  labelHome: 'Home',
  labelEvent: 'Event',
  labelConfig: 'Settings',
  labelUser: 'User',
  labelMailer: 'Mailer',
  labelAbout: 'About',
  labelLog: 'Log',
  labelRiddle: 'Riddle',
  labelTrello: 'Trello',
  labelKeys: 'Keys'
});

i18n.addResourceBundle('fr', 'app', {
  labelHome: 'Accueil',
  labelEvent: 'Événement',
  labelConfig: 'Paramètres',
  labelUser: 'Utilisateur',
  labelMailer: 'Messagerie',
  labelAbout: 'A propos',
  labelLog: 'Log',
  labelRiddle: 'Enigme',
  labelTrello: 'Trello',
  labelKeys: 'Clés'
});

export default i18n;