import { GatewayPassword } from "@raidinlyon/core";
import { useLocation, useNavigate } from "react-router-dom";


export default function RouteGatewayPassword() {
    const { search } = useLocation();
    const navigate = useNavigate();

    const getToken = () => {
        const value = new URLSearchParams(search).get('t');
        if (!value) {
            return ''
        }
        return value;
    } ;

    const handleReset = () => navigate('/login')

    return <GatewayPassword image='/logo.png' token={getToken()} onReset={handleReset} />
}