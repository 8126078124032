
import { useParams } from "react-router-dom";
import { 
	RAIDinLyonMainEvent,
	RAIDinLyonMainRiddle,
	useEvents 
} from "@raidinlyon/core";
import { useObservable } from "@raidinlyon/db";
import React from "react";

export function useEvent() {
	const { eventnumber } = useParams();
	const events = useEvents();
	const [event, setEvent] = React.useState<RAIDinLyonMainEvent>();

	React.useEffect(() => {
		if (eventnumber && events) {
			setEvent(events.getEvent(Number.parseInt(eventnumber)))
		} else {
			setEvent(undefined)
		}
	}, [eventnumber, events])

	return event;
}

export function useRiddle() {
	const { eventnumber, riddlenumber } = useParams();
	const events = useEvents();
	const [riddle, setRiddle] = useObservable<RAIDinLyonMainRiddle>();

	React.useEffect(() => {
		if (events && eventnumber && riddlenumber) {
			const rd = events.getRiddle(Number.parseInt(eventnumber), Number.parseInt(riddlenumber));
			if (rd) {
				setRiddle(rd);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [events, eventnumber, riddlenumber])

	return riddle;
}
