import { Riddle } from "@raidinlyon/core";
import { useEvent, useRiddle } from "../Hook";
import { useNavigate, useParams } from "react-router-dom";

export default function RouteRiddleViewer() {
	const event = useEvent();
	const riddle = useRiddle();
	const { eventnumber, riddlenumber } = useParams();
	const navigate = useNavigate();

	const handleNext = () => {
		navigate('/app/event/' + eventnumber + '/' + (Number(riddlenumber) + 1))
	}

	const handlePrevious = () => {
		navigate('/app/event/' + eventnumber + '/' + (Number(riddlenumber) - 1))
	}

	return riddle && event ? <Riddle
		value={riddle}
		event={event}
		regexHelpPdf="/memo_regexp.pdf"
		onNext={handleNext}
		onPrevious={handlePrevious}
	/> : <div></div>
}