import { RAIDinLyonRiddleSearch, Home } from "@raidinlyon/core";
import { useNavigate } from "react-router-dom";

export default function HomeComponent() {
  const navigate = useNavigate();

  const handleOpenRiddle = (riddle: RAIDinLyonRiddleSearch) => {
    navigate('/app/event/' + riddle.eventNumber + '/' + riddle.number)
  }

  const handleOpenRiddleTable = () => {
    navigate('/app/riddle')
  }

  const handleOpenRiddleTableEditor = () => {
    navigate('/app/riddle/editor')
  }

  const handleOpenRiddleTableProofreader = () => {
    navigate('/app/riddle/proofreader')
  }

  return <Home 
    onOpenRiddle={handleOpenRiddle} 
    onOpenMoreRiddle={handleOpenRiddleTable} 
    onOpenMoreUserEditorRiddle={handleOpenRiddleTableEditor}
    onOpenMoreUserProofreaderRiddle={handleOpenRiddleTableProofreader}
  />
}