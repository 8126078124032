import { GatewayLogin } from "@raidinlyon/core"
import { useNavigate } from "react-router-dom";

export default function RouteGatewayLogin() {
    const navigate = useNavigate();

    const handleClickResetPassword = () => navigate('/password/reset')

    return (
        <GatewayLogin image='/logo.png' onClickResetPassword={handleClickResetPassword}/>
    )
}