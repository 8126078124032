import { useNavigate } from "react-router-dom";
import EventIcon from '@mui/icons-material/Event';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Configuration, TrelloIcon } from "@raidinlyon/core";
import { useTranslation } from "react-i18next";

export default function RouteConfigurationMenu() {
    const navigate = useNavigate();
	const { t } = useTranslation('app');
    
    const items = [{
        Icon: EventIcon,
        text: t('labelEvent'),
        onClick: () => navigate('/app/config/event')
    }, {
        Icon: TrelloIcon,
        text: t('labelTrello'),
        onClick: () => navigate('/app/config/trello')
    }, {
        Icon: SettingsIcon,
        text: t('labelRiddle'),
        onClick: () => navigate('/app/config/riddle')
    },{
        Icon: PeopleIcon,
        text: t('labelUser'),
        onClick: () => navigate('/app/config/users')
    }, {
        Icon: TextSnippetIcon,
        text: t('labelLog'),
        onClick: () => navigate('/app/config/log')
    }, {
        Icon: InfoIcon,
        text: t('labelAbout'),
        onClick: () => navigate('/app/config/info')
    }]

    return (
        <Configuration
            value={items}
        />
    )
}
