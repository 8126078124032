import { useUser } from "@raidinlyon/core";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

export default function RouteConfiguration() {
    const navigate = useNavigate();
    const user = useUser();
    
    React.useEffect(() => {
        if (user && !user.admin) {
            navigate('/app', { replace: true })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    return <Outlet/>
}